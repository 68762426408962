
body {
  font-family: 'Rubik Variable', sans-serif !important;
}

.font-face-rl {
  font-family: "Rubik" !important;
  font-weight: 100 !important;
}


h1, h2, h3, h4, h5, h6, .faq .question {
  font-family: 'Rubik Variable', sans-serif !important;
  font-weight: 500 !important;

}

p{
  font-family: 'Rubik Variable', sans-serif !important;
  font-weight: 300 !important;
}

h1{
  font-size: 2.125rem !important;
}

h2{
  font-size: 1.525rem !important;
}

.tos h1, .tos h2,.tos h3,.tos   h4,.tos h5,.tos h6,.tos p{
  margin-bottom: 1.4rem !important;
}





